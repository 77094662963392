import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IUser} from "../../common/types/user";
import api from "../../api/baseApi";
import {fakeUsers} from "../../MokeDataBase/users";

export interface UserState {
  users: IUser[];
  currentUser?: IUser | null;
  loading: boolean;
  error: string | null;
};

const initialState: UserState = {
  users: [],
  currentUser: null,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
    'users',
    async (_, { rejectWithValue }) => {
      try {
        if (process.env.REACT_APP_IS_MOCK_MODE !== "true")
        {
          const result: IUser[] = await api(`/users`, {
            headers: {
              credentials: "include",
            },
          });
          return result;
        } else {
          return fakeUsers;
        }
      } catch (error: any) {
        return rejectWithValue(error.message || 'Something went wrong');
      }
    }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchUsers.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<IUser[]>) => {
          state.users = action.payload;
          state.loading = false;
        })
        .addCase(fetchUsers.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
  },
});

export const UsersReducer = userSlice.reducer;
