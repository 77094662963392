import React, {FC} from 'react';

interface UserFormInputProps {
    placeholder: string;
    value: string;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
    isValid?: boolean;
    name: string;
    onPaste?: (e: any) => void;
    type?: string;
}

const AddUserInput: FC<UserFormInputProps> = ({placeholder, onChange, value, isValid, onPaste, onBlur, name, type}) => {
    return (
        <input
            className={`w-full mt-1  border border-[#252627]  transition-colors rounded-md  px-2 py-1 ${isValid ? 'bg-[#252627] text-[#d7d7d7]' : 'bg-rose-800 text-white'}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            name={name}
            onPaste={onPaste}
            type={type}
        />
    );
};

export default AddUserInput;