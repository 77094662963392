import React, { useRef, useEffect, useState } from "react";
import { IMessage } from "../../../../common/types/message";
import MessageContainer from "../messages-components/MessageContainer";
import Message from "../messages-components/Message";
import { MyPagination } from "../../../../common/types/plagination";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { ICustomer } from "../../../../common/types/customer";
import api from "../../../../api/baseApi";
import { setMessages } from "../../../../store/slices/messages-slice";
import { IDialog } from "../../../../common/types/dialog";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { ITelegramUser } from "../../../../common/types/telegram-user";

const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

const getScrollCookieKey = (dialogId: string) => `scrollPosition_${dialogId}`;

const MessagesWindow = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages.messages);
  const dialog: IDialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const customerId = customer.tgId;
  const [messagesPagination, setMessagesPagination] = useState<MyPagination>({
    page: 0,
    limit: 50,
    skip: 0,
  });

  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleScroll = () => {
    if (messagesWindowRef.current) {
      const position = messagesWindowRef.current.scrollTop;
      setScrollPosition(position);
    }
  };

  const saveScrollPosition = () => {
    if (messagesWindowRef.current && dialog) {
      const currentScrollPosition = messagesWindowRef.current.scrollTop;
      const cookieKey = getScrollCookieKey(dialog.tgId);

      const savedPosition = getCookie(cookieKey);

      if (
        savedPosition === undefined ||
        parseInt(savedPosition, 10) !== currentScrollPosition
      ) {
        setCookie(cookieKey, currentScrollPosition.toString(), 7);
      }
    }
  };

  const restoreScrollPosition = () => {
    if (messagesWindowRef.current && dialog) {
      const cookieKey = getScrollCookieKey(dialog.tgId);
      const savedPosition = getCookie(cookieKey);

      if (savedPosition !== undefined) {
        const position = parseInt(savedPosition, 10);
        if (messagesWindowRef.current.scrollTop !== position) {
          messagesWindowRef.current.scrollTop = position;
        }
      }
    }
  };

  const fetchMessages = async (page?: number) => {
    try {
      const messagesData: any = await api(
        `/customers/messages?dialogId=${
          dialog.tgId
        }&customerId=${customerId}&page=${
          page ? page : messagesPagination.page
        }&limit=${messagesPagination.limit}`,
        {
          headers: {
            credentials: "include",
          },
        }
      );
      const fetchedMessages = messagesData.messages as IMessage[];

      if (isLoading) {
        setIsLoading(false);
      }

      const allMessages = [...(messages || []), ...fetchedMessages];

      const uniqueMessages = Array.from(
        new Map(allMessages.map((msg) => [msg.tgId, msg])).values()
      ).sort((a, b) => parseInt(b.tgId) - parseInt(a.tgId));

      dispatch(
        setMessages({
          messages: uniqueMessages,
          total: messagesData.total,
        })
      );
    } catch (error) {
      console.log("Error fetching dialogs", error);
    }
  };

  const checkScrollTop = async () => {
    if (!messagesWindowRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messagesWindowRef.current;
    if (Math.abs(scrollTop) + clientHeight >= scrollHeight) {
      setMessagesPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      await fetchMessages();
    }
  };

  const getMediaPlaceholder = (message: IMessage): string | null => {
    if (!message.text && (!message.media || message.media.length === 0)) {
      return "Неизвестное сообщение";
    }
    return null;
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    const divRef = messagesWindowRef.current;

    if (divRef) {
      divRef.addEventListener("scroll", handleScroll);
      divRef.addEventListener("scroll", saveScrollPosition);
      divRef.addEventListener("scroll", checkScrollTop);

      return () => {
        if (divRef) {
          divRef.removeEventListener("scroll", handleScroll);
          divRef.removeEventListener("scroll", saveScrollPosition);
          divRef.removeEventListener("scroll", checkScrollTop);
        }
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading && messages.length > 0) {
      restoreScrollPosition();
    }
  }, [isLoading]);

  if (isLoading) {
    return <div>Загрузка ...</div>;
  }

  if (!messages) {
    return <div>Сообщения отсутствуют</div>;
  }

  return (
    <div>
      <div
        ref={messagesWindowRef}
        className={"flex flex-col-reverse gap-y-2 max-h-[600px] overflow-auto"}
      >
        {messages.map((message) => {
          const sender: ITelegramUser = dialog.users?.find(
            (user) => user.tgId === message.senderId
          ) as ITelegramUser;

          const mediaPlaceholder = getMediaPlaceholder(message);

          return (
            <MessageContainer
              customerId={customerId}
              senderId={message.senderId || ""}
              key={message.tgId}
            >
              <Message
                message={message}
                sender={sender}
                placeholder={mediaPlaceholder}
              />
            </MessageContainer>
          );
        })}
      </div>
    </div>
  );
};

export default MessagesWindow;
