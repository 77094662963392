import axios, {Method} from "axios";
import {getTokens, removeTokens, setTokens} from "../common/util/tokens";
import {Tokens} from "../common/types/token";

axios.defaults.withCredentials = true;

type Options = {
    method?: Method;
    headers?: any;
    data?: any;
};

export const BASE_API = process.env.REACT_APP_API_URL;

const apiInstance = axios.create({
    baseURL: BASE_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiInstance.interceptors.request.use(
    (config) => {
        const tokens = getTokens();
        if (tokens && tokens.access_token) {
            config.headers['Authorization'] = `Bearer ${tokens.access_token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


apiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (!getTokens()) return;

        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            try {
                const newTokens = await refreshToken();
                if (newTokens) {
                    originalRequest.headers['Authorization'] = `Bearer ${newTokens.access_token}`;
                    return apiInstance(originalRequest);
                }
            } catch (err) {
                console.error('Ошибка обновления токенов:', err);
                removeTokens();
                window.location.href = '/admin/sign-in';
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);

async function refreshToken(): Promise<Tokens | null> {
    try {
        const tokens = getTokens();
        if (!tokens || !tokens.refresh_token) {
            throw new Error('No refresh token available');
        }

        const response = await axios.get(`${BASE_API}/auth/admin/refresh`, {
            headers: {
                Authorization: `Bearer ${tokens.refresh_token}`,
            },
        });

        const newTokens: Tokens = response.data;
        setTokens(newTokens);
        return newTokens;
    } catch (error) {
        removeTokens();
        throw error;
    }
}

async function api<T>(
    url: string,
    options?: Options,
    queryParams?: Record<string, string>
): Promise<T> {
    if (queryParams) {
        url += '?' + new URLSearchParams(queryParams).toString();
    }

    try {
        const response = await apiInstance({
            url,
            method: options?.method,
            headers: options?.headers,
            data: options?.data,
        });

        return response.data as T;
    } catch (error: any) {
        console.log(error)
        if (error.response && error.response.status === 401) {
            const tokens = await refreshToken();
            if (!tokens) {
                removeTokens();
            } else {
                setTokens(tokens as Tokens);
            }
        }
        throw error;
    }
}

export default api;
