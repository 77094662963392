export const fakeUsers = [
    {
        id: "1",
        nickname: 'tom',
        password: '12345678'
    },
    {
        id: "2",
        nickname: 'jane_doe',
        password: 'password123'
    },
    {
        id: "3",
        nickname: 'john_smith',
        password: 'qwertyuiop'
    },
    {
        id: "4",
        nickname: 'alice_wonderland',
        password: 'alice1234'
    },
    {
        id: "5",
        nickname: 'bob_builder',
        password: 'builder2021'
    },
    {
        id: "6",
        nickname: 'charlie_brown',
        password: 'charlie123'
    },
    {
        id: "7",
        nickname: 'david_jones',
        password: 'davidpass'
    },
    {
        id: "8",
        nickname: 'eve_adams',
        password: 'evepassword'
    },
    {
        id: "9",
        nickname: 'frank_castle',
        password: 'punisher'
    },
    {
        id: "10",
        nickname: 'grace_hopper',
        password: 'grace123'
    }
];
