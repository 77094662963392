import React, {useEffect, useState} from "react";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import {CiSquarePlus} from "react-icons/ci";
import UsersDialogWindow from "./add-user-dialog-window/UsersDialogWindow";
import UsersList from "./UsersList";
import {IUser} from "../../../common/types/user";
import RemoveUserDialogWindow from "./remove-user-dialog-window/RemoveUserDialogWindow";
import EditUserDialogWindow from "./update-user-dialog-window/EditUserDialogWindow";
import Bugsnag from "@bugsnag/js";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {fetchUsers} from "../../../store/slices/user-slice";

export default function UsersPage() {
    const [isWindowShow, setIsWindowShow] = useState(false);
    const [editingUser, setEditingUser] = useState<IUser | null>(null);
    const [removedUser, setRemovedUser] = useState<IUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const usersData = useAppSelector((state) => state.user.users);

    const hideAndUpdate = () => {
        setIsWindowShow(false);
    };

    const updateUser = async (user: IUser) => {
        try {
            await api(`users/update?id=${user.id}`, {
                method: "PUT",
                data: { ...user },
                headers: {
                    "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
                },
            });
            setEditingUser(null);
            dispatch(fetchUsers());
        } catch (error: any) {
            console.log(error);
        }
    };

    const deleteUser = async () => {
        try {
            await api(`users/delete?id=${removedUser?.id}`, {
                method: "DELETE",
                headers: {
                    "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
                },
            });
            setRemovedUser(null);
            dispatch(fetchUsers());
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        dispatch(fetchUsers);
        setIsLoading(false);
        Bugsnag.notify(new Error("Test prod error"));
    }, []);

    return (
        <>
            {isWindowShow && (
                <UsersDialogWindow
                    hideCb={() => setIsWindowShow(false)}
                    hideAndUpdate={hideAndUpdate}
                />
            )}
            {removedUser && (
                <RemoveUserDialogWindow
                    hide={() => {
                        setRemovedUser(null);
                    }}
                    removeUser={() => deleteUser()}
                />
            )}
            {editingUser && (
                <EditUserDialogWindow
                    hide={() => setEditingUser(null)}
                    updateUser={(user) => updateUser(user)}
                    user={editingUser}
                />
            )}
            <ContentContainer>
                <CustomersInfoCard customPadding={"p-[30px]"}>
                    <div className={"flex flex-col gap-5 w-full"}>
                        <button
                            onClick={() => setIsWindowShow((prevState) => !prevState)}
                            className={
                                "bg-[#141515] border border-[#252627] w-[350px]  text-[#d7d7d7] transition-colors items-center rounded-md hover:bg-[#3d3e3f] gap-x-4  px-8 p-3 flex justify-between"
                            }
                        >
                            Добавить нового пользователя
                            <CiSquarePlus size={25}/>
                        </button>
                        {isLoading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <UsersList
                                    users={usersData}
                                    deleteUser={(user) => {
                                        setRemovedUser(user);
                                    }}
                                    updateUser={(user) => {
                                        setEditingUser(user);
                                    }}
                                />
                            </>
                        )}
                    </div>
                </CustomersInfoCard>
            </ContentContainer>
        </>
    );
}
