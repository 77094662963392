import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {removeTokens} from "../../common/util/tokens";

interface AuthState {
  isAuthenticated: boolean;
  phone: string | null;
  code: string | null;
  redirect_url: string | null,
  elevated_rights: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!(Cookies.get("access_token") && Cookies.get("refresh_token")),
  phone: null,
  code: null,
  redirect_url: null,
  elevated_rights: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      removeTokens();
      state.isAuthenticated = false;
    },
    setPhoneState(state, action) {
      state.phone = action.payload;
    },
    setRedirectUrl(state, action) {
      state.redirect_url = action.payload;
    },
    setCodeState(state, action) {
      state.code = action.payload;
    },
  },
});

export const { login, logout, setPhoneState, setRedirectUrl, setCodeState } = authSlice.actions;

export const AuthReducer = authSlice.reducer;
