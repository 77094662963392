import React, {FC, useState} from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../../api/baseApi";
import {IUser} from "../../../../common/types/user";
import AddUserInput from "./AddUserInput";

interface AddUserFormProps {
    hideAndUpdate: (values: IUser) => void;
}

export interface AddUserFormValues {
    nickname: string;
    password: string;
}

const AddUserForm: FC<AddUserFormProps> = ({ hideAndUpdate }) => {
    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = yup.object({
        nickname: yup.string()
            .required("Поле никнейм обязательное")
            .matches(/^.{1,}$/, "Неверный формат никнейма (никнейм должен содержать больше 2 символов)"),
        password: yup
            .string()
            .required("Пароль обязателен")
            .matches(/^.{7,}$/, "Неверный формат пароля(пароль должен содержать больше 7 символов)"),
    });

    const form = useFormik({
        initialValues: {
            nickname: "",
            password: "",
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            await handleSubmit(values);
        },
    });

    const isFormValid = () => {
        return (
            !Object.keys(form.errors).length &&
            Object.values(form.values).every((value) => value.trim() !== "")
        );
    };

    const handleChange = (field: string) => (e: any) => {
        form.handleChange(e);
        form.validateField(field);
    };

    const handleBlur = (field: string) => (e: any) => {
        form.handleBlur(e);
        form.validateField(field);
    };

    const handleSubmit = async (values: AddUserFormValues) => {
        if (!isFormValid()) {
            return;
        }
        try {
            const result = await api<IUser>("users/create", {
                data: {
                    nickname: values.nickname,
                    password: values.password,
                },
                method: "POST",
                headers: {
                    credentials: "include",
                },
            });
            hideAndUpdate(result);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form
            className={
                "w-full min-w-[400px] gap-y-[20px] px-[60px] pb-[30px] flex flex-col justify-center items-center"
            }
        >
            <h3 className={"text-2xl font-bold self-start pb-4"}>Добавление пользователя</h3>
            <label
                className={"w-full flex gap-y-1 flex-col items-start justify-center"}
            >
                <span className={"font-light text-base"}>Nickname</span>
                <AddUserInput
                    placeholder={"Nickname"}
                    isValid={!!!form.errors.nickname}
                    value={form.values.nickname}
                    onBlur={handleBlur("nickname")}
                    onChange={handleChange("nickname")}
                    name={"nickname"}
                />
                <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
                    {form.errors.nickname && form.errors.nickname}
                </p>
            </label>
            <label className={'w-full flex gap-y-1 flex-col items-start justify-center'}>
                <span className={'font-light text-base'}>Password</span>
                <div className="relative w-full">
                    <AddUserInput
                        placeholder="********"
                        isValid={!(form.touched.password && form.errors.password)}
                        value={form.values.password}
                        onBlur={handleBlur("password")}
                        onChange={handleChange("password")}
                        name={"password"}
                        type={showPassword ? "text" : "password"}
                    />
                    <button
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                        data-hs-toggle-password='{
                          "target": "#hs-pro-dalp"
                        }'
                        className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
                    >
                        <svg
                            className="flex-shrink-0 size-3.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                        >
                            <path
                                className="hs-active:hidden"
                                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                            />
                            <path
                                className="hs-active:hidden"
                                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                            />
                            <path
                                className="hs-active:hidden"
                                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                            />
                            <path
                                className="hidden hs-active:block"
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            />
                            <path
                                className="hidden hs-active:block"
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            />
                        </svg>
                    </button>
                </div>
                <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
                    {form.errors.password && form.errors.password}
                </p>
            </label>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form.handleSubmit();
                }}
                type={"submit"}
                className={`mt-[20px] px-[80px] py-[10px] rounded-md transition-colors ${
                    !isFormValid()
                        ? "bg-gray-300 cursor-default text-gray-700"
                        : "hover:bg-blue-600 bg-blue-500 cursor-pointer"
                }`}
            >
                Добавить пользователя
            </button>
        </form>
    );
};

export default AddUserForm;
