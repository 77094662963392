import React from "react";
import { VscDebugRerun } from "react-icons/vsc";
import { FaRegStopCircle } from "react-icons/fa";
import api from "../../api/baseApi";
import { ICustomer } from "../../common/types/customer";
import { toast } from "react-toastify";

interface SubscribeButtonProps {
  customer: ICustomer;
}

export default function SubscribeButton({ customer }: SubscribeButtonProps) {
  const [active, setActive] = React.useState(customer.cronStatus || false);

  const runCron = async () => {
    try {
      const res = await api("/cron/start", {
        method: "POST",
        data: {
          clientId: customer.id,
          tgId: customer.tgId,
        },
      });
      if (res === "Success") {
        setActive(true);
        toast.success("Крона стартовала успешно");
      }
    } catch (error) {
      toast.error("Ошибка старта кроны");
      console.error("Error reparsing data:", error);
    }
  };

  const stopCron = async () => {
    try {
      const res = await api("/cron/stop", {
        method: "DELETE",
        data: {
          clientId: customer.id,
        },
      });
      if (res === "Success") {
        setActive(false);
        toast.success("Крона остановлена успешно");
      }
    } catch (error) {
      toast.error("Ошибка остановки кроны");
      console.error("Error reparsing data:", error);
    }
  };

  const clickButtonHandler = () => {
    if (active) {
      stopCron();
    } else {
      runCron();
    }
  };

  return (
    <button
      onClick={clickButtonHandler}
      aria-label="Статус кроны"
      title="Start cron job"
      className={`flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg ${
        active
          ? "bg-[#e44040aa] hover:bg-[#e44040aa] border-red-700"
          : "bg-[#2dd747aa] hover:bg-[#38a147aa] border-green-700"
      } border  z-30 relative`}
    >
      {active ? <FaRegStopCircle size={18} /> : <VscDebugRerun size={18} />}
    </button>
  );
}
