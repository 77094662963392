import React, {FC} from 'react';
import { IoMdClose } from "react-icons/io";
import AddUserForm from "./AddUserForm";
import {IUser} from "../../../../common/types/user";

interface UsersDialogWindowProps {
    hideCb: () => void;
    hideAndUpdate: (link: IUser) => void;
}

const UsersDialogWindow: FC<UsersDialogWindowProps> = ({hideCb, hideAndUpdate}) => {
    return (
        <div
            onClick={hideCb}
            className={'absolute w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center'}>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`bg-[#161616]  text-[#d7d7d7] cursor-default rounded-md max-w-[750px]  border-2 border-[#252627] w-full max-h-[600px] flex flex-col`}
            >
                <div className={'w-full flex justify-end px-[25px] pt-[25px]'}>
                    <button
                        onClick={hideCb}
                        className={'hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center'}>
                        <IoMdClose  size={25}/>
                    </button>
                </div>
                <AddUserForm hideAndUpdate={hideAndUpdate}/>
            </div>
        </div>
    );
};

export default UsersDialogWindow;