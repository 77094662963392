import React from "react";
import { LuRefreshCw } from "react-icons/lu";
import api from "../../api/baseApi";
import { toast } from "react-toastify";

interface RefreshChatButtonProps {
  customerId: string;
  dialogId: string;
}

const RefreshChatButton: React.FC<RefreshChatButtonProps> = ({
  customerId,
  dialogId,
}) => {
  const clearCache = async () => {
    try {
      const cacheNames = await caches.keys();

      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    } catch (error) {}
  };

  const refreshChat = async () => {
    await clearCache();

    try {
      const response = await api("customers/refresh-dialog", {
        method: "PUT",
        data: {
          customerId,
          dialogId,
        },
      });

      if (response) {
        toast.success("Чат успешно обновлён");
        console.log(response);
      }
    } catch (error) {
      toast.error("Ошибка обновления чата");
      console.error("Error refreshing chat:", error);
    }
  };

  return (
    <button
      onClick={refreshChat}
      title="Обновить чат"
      className="flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg bg-[#2d2e2f] hover:bg-[#1c1c1d]"
    >
      <LuRefreshCw size={23} />
    </button>
  );
};

export default RefreshChatButton;
