import React, { useEffect } from "react";
import api from "../../../api/baseApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCustomer } from "../../../store/slices/customer-slice";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import { ICustomer } from "../../../common/types/customer";
import CustomersList from "./CustomersList";
import { useLoading } from "../../../providers/LoadingContext";
import Loader from "../../../components/loader/Loader";
import { toast } from "react-toastify";

export default function CustomersPage() {
  const { isLoading, setLoading } = useLoading();
  const dispatch = useAppDispatch();
  const customersData = useAppSelector((state) => state.customer.customers);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const customers: ICustomer[] = await api(`/customers`, {
        headers: {
          credentials: "include",
        },
      });
      if (customers) {
        dispatch(setCustomer(customers));
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching customers", error);
      toast.error("Ошибка при загрузке клиентов");
    } finally {
      setLoading(false);
    }
  };

  const clearCache = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    } catch (error) {
      console.error("Ошибка при очистке кэша:", error);
    }
  };

  const deleteCustomer = async (customerId: string) => {
    try {
      await clearCache();

      await api(`customers/dialog/${customerId}`, {
        method: "DELETE",
        headers: {
          credentials: "include",
        },
      });

      dispatch(
        setCustomer(
          customersData.filter((customer) => customer.tgId !== customerId)
        )
      );
      toast.success("Клиент успешно удалён");

      fetchCustomers();
    } catch (error) {
      console.log("Error deleting customer", error);
      toast.error("Ошибка при удалении клиента");
    }
  };

  useEffect(() => {
    if (!customersData.length) {
      fetchCustomers();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        {isLoading ? (
          <Loader />
        ) : (
          <CustomersList customers={customersData} onDelete={deleteCustomer} />
        )}
      </CustomersInfoCard>
    </ContentContainer>
  );
}
