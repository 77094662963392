import {useAppSelector} from "../hooks/reduxHooks";
import {Navigate} from "react-router-dom";
import React from "react";

interface ProtectedRouteProps {
    element: JSX.Element;
}

const CheckElevatedRight = ({ element }: ProtectedRouteProps) => {
    const authState = useAppSelector((state) => state.auth);

    return authState.elevated_rights ? element : <Navigate to="/t" />;
};

export default CheckElevatedRight;
